import React from "react"
import { useField } from "formik"
import { Input } from "reactstrap"

import { LabelBox } from "./labelBox"

// @ts-ignore
export const FormikInput = ({ label, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props)

  return (
    <LabelBox error={meta.error} label={label}>
      <Input onClick={(e) => e.stopPropagation()} className="data-hj-allow" {...field} {...props} />
    </LabelBox>
  )
}
